var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col h-full justify-between"},[_c('div',{staticClass:"flex flex-row text-gray-800 pt-3 pb-4 items-center relative z-20 subject-area"},[_c('svg',{staticClass:"h-6 fill-current pl-3 pr-2 cursor-pointer",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"},on:{"click":_vm.closeThread}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"}})]),_c('span',{staticClass:"text-lg text-center pl-2"},[_vm._v(_vm._s(_vm.thread))])]),_c('div',{staticClass:"flex flex-col mt-auto pt-4 overflow-hidden overflow-y-auto",class:{
            'pb-4': !_vm.canRespond,
        }},_vm._l((_vm.messages),function(message,idx){return _c('div',{key:idx,staticClass:"flex",class:{
                'pl-3 pr-4 justify-start': message.direction === 'incoming',
                'pr-3 pl-16 justify-end': message.direction === 'outgoing',
                'mb-4': idx !== _vm.messages.length - 1,
            }},[(message.direction === 'incoming')?_c('div',{staticClass:"self-end mr-1"},[_c('svg',{staticClass:"h-10 text-purple-600 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"}})])]):_vm._e(),_c('div',{staticClass:"py-2 px-4 rounded-lg",class:{
                    'bg-gray-200 text-gray-800': message.direction === 'incoming',
                    'bg-blue-600 text-white': message.direction === 'outgoing',
                }},[_c('p',[_vm._v(_vm._s(message.message))])])])}),0),(_vm.canRespond)?_c('div',{staticClass:"p-3"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.message),expression:"message",modifiers:{"trim":true}}],staticClass:"js-message appearance-none block text-gray-800 w-full rounded-lg border border-gray-400 p-3 resize-none leading-tight focus:outline-none",attrs:{"placeholder":"Text message"},domProps:{"value":(_vm.message)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.sendChat.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }