<template>
    <div class="flex flex-col h-full justify-between">
        <div class="flex flex-row text-gray-800 pt-3 pb-4 items-center relative z-20 subject-area">
            <svg
                @click="closeThread"
                class="h-6 fill-current pl-3 pr-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
            <span class="text-lg text-center pl-2">{{ thread }}</span>
        </div>
        <div
            class="flex flex-col mt-auto pt-4 overflow-hidden overflow-y-auto"
            :class="{
                'pb-4': !canRespond,
            }">
            <div
                v-for="(message, idx) in messages"
                :key="idx"
                class="flex"
                :class="{
                    'pl-3 pr-4 justify-start': message.direction === 'incoming',
                    'pr-3 pl-16 justify-end': message.direction === 'outgoing',
                    'mb-4': idx !== messages.length - 1,
                }">
                <div
                    v-if="message.direction === 'incoming'"
                    class="self-end mr-1">
                    <svg class="h-10 text-purple-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>
                </div>
                <div
                    class="py-2 px-4 rounded-lg"
                    :class="{
                        'bg-gray-200 text-gray-800': message.direction === 'incoming',
                        'bg-blue-600 text-white': message.direction === 'outgoing',
                    }">
                    <p>{{ message.message }}</p>
                </div>
            </div>
        </div>
        <div
            v-if="canRespond"
            class="p-3">
            <textarea
                class="js-message appearance-none block text-gray-800 w-full rounded-lg border border-gray-400 p-3 resize-none leading-tight focus:outline-none"
                placeholder="Text message"
                v-model.trim="message"
                @keyup.enter.prevent="sendChat">
            </textarea>
        </div>
    </div>
</template>

<script>
import { autoResizeTextArea } from '../../../utils/dom';

export default {
    name: 'ThreadMessages',

    props: {
        thread: {
            type: String,
            default: () => '',
        },

        messages: {
            type: Array,
            default: () => [],
        },

        closeThread: {
            type: Function,
            default: () => () => {},
        },

        markThreadRead: {
            type: Function,
            default: () => () => {},
        },

        sendMessage: {
            type: Function,
            default: () => () => {},
        },
    },

    data() {
        return {
            message: '',
            localMessages: [],
        };
    },

    computed: {
        canRespond() {
            return /^\d/.test(this.thread);
        },
    },

    watch: {
        messages(newValue) {
            this.localMessages = newValue;
        },
    },

    mounted() {
        autoResizeTextArea('.js-message', 150);
    },

    beforeDestroy() {
        this.markThreadRead(this.thread);
    },

    methods: {
        sendChat() {
            if (this.message) {
                this.sendMessage({
                    to: this.thread,
                    message: this.message,
                });
                this.message = '';
            }
        },
    },
};
</script>
